import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Link from 'gatsby-plugin-transition-link'
import styled from 'styled-components'

import colors from '../_vars/colors'
import sizes from '../_vars/sizes'
import '../layouts/global.scss'

let menuTransitionTime = `0.25`

const Menu = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
`

const Item = styled.li`
  a {
    &:visited span:after {
      color: ${colors.lightText0};
    }
  }
`

const ProjectLink = styled.span`
  &:after {
    text-transform: uppercase;
    float: right;
    letter-spacing: 0.05em;
    color: rgb(255, 255, 255);
    transition: ${menuTransitionTime}s;
    content: '✓';
    font-size: 1em;

    @media (min-width: ${sizes.medScreen}) {
      content: 'Viewed';
      font-size: 0.625em;
      transform: translateY(0.4em);
    }
  }
`

class HeaderMenu extends React.Component {
  render() {
    const { data } = this.props
    const projects = data.allMdx.edges
    return (
      <Menu>
        {projects.map(({ node }) => (
          <Item key={node.id}>
            <Link to={`/${node.fields.slug}/`} activeClassName="active">
              <div className="main-column">
                <span>{node.frontmatter.designer}</span>
                <ProjectLink>{node.frontmatter.project}</ProjectLink>
              </div>
            </Link>
          </Item>
        ))}
      </Menu>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allMdx(sort: { order: ASC, fields: [frontmatter___project] }) {
          edges {
            node {
              id
              frontmatter {
                project
                homepageColor
                designer
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `}
    render={data => <HeaderMenu data={data} {...props} />}
  />
)
