import React from 'react'
// import { Link } from 'gatsby'
import TransitionLink from 'gatsby-plugin-transition-link'
import styled, { css } from 'styled-components'
import IconMenu from './icon-menu'
import HeaderMenu from './header__menu'
import sizes from '../_vars/sizes'
import colors from '../_vars/colors'

import '../layouts/global.scss'

let menuTransitionTime = `0.25`

const MenuScrim = styled.div`
  pointer-events: ${props => (props.menuOpen ? 'auto' : 'none')};
  background: ${props =>
    props.menuOpen ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)'};
  transition: background-color 0.25s;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  /* z-index: 99998; */
`

const Nav = styled.nav`
  position: fixed;
  display: flex;
  width: 100vw;
  z-index: 99997;
  /* background: #ddd; */
  /* box-shadow: ${colors.shadow}; */
  background: transparent;
  display: grid;
  align-content: center;
  height: auto;
  height: ${sizes.navBarHeight};

  transform: translateY(${props =>
    props.scrollingDown === true &&
    props.scrollPos >= 48 &&
    props.menuOpen === false
      ? `-${sizes.navBarHeight}`
      : '0'});
  transition: transform .25s ease;

  a, button span {
    font-size: calc(0.75rem + 0.875vmin);

    @media (min-width: ${sizes.smScreen}) {
      font-size: 1rem;
    }
    @media (min-width: ${sizes.wideScreen}) {
      font-size: 1.25rem;
    }
    display: block;
    color: ${colors.card};
    font-weight: 700;
    text-decoration: none;
    transition: color 0.25s ease;

    @media (hover: hover) {
      &:hover {
        color: ${colors.accentColorful};
      }
    }

    &:focus {
      outline: none;
      /* outline: 4px ${colors.accentColorful} solid; */
      background-color: ${colors.accent};
      /* border: 2px ${colors.accentColorful} solid;  */
      color: ${colors.accentColorful};
    }
  }



  .active {
      color: ${colors.accentColorful};
      font-weight: 700;
    }
`

const MainHeader = styled.div`
  display: grid;
  grid-template-columns: min-content max-content;
  justify-content: space-between;
  grid-gap: ${sizes.padding1};
  align-content: center;
  z-index: 99999;
  /* background: ${colors.accent}; */
  background: ${colors.accent};

  a {
    color: ${colors.text};

  }
  
  /* height: 3rem; */
  height: ${sizes.navBarHeight};

  button,
  a {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    width: auto;
    padding: 0 ${sizes.padding1};
  }
`

const SiteNav = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: ${sizes.padding2};
  align-content: center;
  justify-content: space-between;
`

const MenuButton = styled.button`
  background-color: transparent;
  transition: background-color 0.25s ease;
  border: none;
  padding: 0;
  height: ${sizes.navBarHeight};

  @media (hover: hover) {
    &:hover {
      background-color: ${colors.lightGray2};
      cursor: pointer;
      span {
        color: ${colors.accentColorful};
      }
      svg path {
        stroke: ${colors.accentColorful};
      }
    }
  }
  &:focus {
    outline: none;
    background-color: ${colors.lightGray2};
  }

  svg {
    display: block;

    path {
      stroke: ${colors.text};
      transition: stroke 0.25s ease, transform 0.25s;

      &:nth-child(1) {
        transform: ${props =>
          props.menuOpen ? 'translateY(95%)' : 'translateY(0%)'};
      }

      &:nth-child(2) {
        transform: ${props =>
          props.menuOpen ? 'translateY(37.5%)' : 'translateY(0%)'};
      }
    }
  }
`

const Menu = styled.div`
  position: fixed;
  top: ${sizes.navBarHeight};
  width: 100vw;
  z-index: 99997;
  background: ${colors.accent};
  max-height: calc(100vh - ${sizes.navBarHeight});
  overflow: auto;
  transform: translateY(-100%);
  transition: transform ${menuTransitionTime}s;

  ${props =>
    props.menuOpen &&
    css`
      opacity: 1;
      transform: translateY(0%);
    `};

  svg path {
    stroke: ${props => (props.menuOpen ? colors.accentColorful : colors.text)};
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
  }
  li a {
    padding: 0 1rem;
    font-weight: 400;
    border-top: 1px solid ${colors.lightGray2};

    div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      height: ${sizes.navBarHeight};
      align-items: center;
      grid-gap: ${sizes.padding1};
      margin: 0 auto;
    }

    &:focus {
      background: ${colors.lightGray1};
      transition: ${menuTransitionTime}s;

      span:after{
        color:${colors.lightGray1};
        transition: ${menuTransitionTime}s;
      }
    }

    @media (hover: hover) {
      &:hover {
        background: ${colors.lightGray1};
        transition: ${menuTransitionTime}s;

        span:after{
          color:${colors.lightGray1};
          transition: ${menuTransitionTime}s;
        }
      }
      &:visited span:after {
            color:${colors.text};
            transition: ${menuTransitionTime}s;
        }
    }
  }

  /* li:last-child {
    a {
      border-bottom: 1px solid ${colors.lightGray2};
    }
  } */

  li a span:last-of-type:before {
    content: '→ ';
    transition: margin-left 0.25s;
  }
  li a:focus span:last-of-type:before {
    margin-left: -0.75em;
  }

  @media (hover: hover) {
      li a:hover span:last-of-type:before {
        margin-left: -0.75em;
      }
      ul a:hover {
        color: ${colors.accentColorful};
      }
    }
`

const Names = styled.div`
  width: 100%;
  opacity: ${props => (props.menuOpen ? '1' : '0')};
  transition: ${menuTransitionTime}s;
`

let lastScrollY = 0

export default class Header extends React.Component {
  constructor(props) {
    super(props)
    this.escFunction = this.escFunction.bind(this)

    this.state = {
      menuOpen: false,
      scrollPos: 0,
      scrollingDown: false,
    }
  }

  // TODO? Add debouncing if performance is bad
  handleScroll = () => {
    this.setState({ scrollPos: lastScrollY })
    window.requestAnimationFrame(() => {
      this.setState({ scrollPos: lastScrollY })
    })

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop

    if (scrollTop > lastScrollY) {
      this.setState({ scrollingDown: true })
    } else {
      this.setState({ scrollingDown: false })
    }
    lastScrollY = scrollTop <= 0 ? 0 : scrollTop
  }

  escFunction() {
    if (this.state.menuOpen) this.toggleMenu()
  }
  // adds listener for 'esc' key press
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    document.addEventListener('keyup', e => {
      if (e.keyCode === 27) this.escFunction()
    })
  }
  // removes listener when component is unmounted
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    document.removeEventListener('keyup', this.escFunction, false)
  }

  toggleMenu = () => {
    let menuOpen = !this.state.menuOpen
    this.setState({ menuOpen })
  }

  render() {
    return (
      <Nav
        scrollingDown={this.state.scrollingDown}
        scrollPos={this.state.scrollPos}
        menuOpen={this.state.menuOpen}
        headerHeight={this.state.headerHeight}
      >
        <MenuScrim menuOpen={this.state.menuOpen} onClick={this.toggleMenu} />
        <MainHeader>
          <TransitionLink
            onClick={e => this.state.menuOpen === true && this.toggleMenu}
            // onFocus={this.state.menuOpen ? this.toggleMenu : false}
            tabIndex="0"
            to="/"
            exit={{
              length: 1,
            }}
            entry={{
              delay: 0,
            }}
          >
            <span>TM18</span>
          </TransitionLink>
          <SiteNav>
            <TransitionLink
              onClick={e => this.state.menuOpen === true && this.toggleMenu}
              to="/about"
              exit={{
                length: 1,
              }}
              entry={{
                delay: 0,
              }}
              activeClassName="active"
            >
              About
            </TransitionLink>
            <MenuButton
              onClick={this.toggleMenu}
              menuOpen={this.state.menuOpen}
              title="toggle projects menu"
            >
              <IconMenu />
            </MenuButton>
          </SiteNav>
        </MainHeader>

        <Menu menuOpen={this.state.menuOpen}>
          <Names menuOpen={this.state.menuOpen}>
            <HeaderMenu onClick={this.toggleMenu} />
          </Names>
        </Menu>
      </Nav>
    )
  }
}
