import React from 'react'
import { Link } from 'gatsby'
import styled, { keyframes } from 'styled-components'
import SEO from '../components/seo'
import colors from '../_vars/colors'
import sizes from '../_vars/sizes'

import Header from '../components/header--full'

const Wrapper = styled.div`
  display: grid;
  height: 100vh;
  overflow-y: hidden;
  width: 100%;
  margin: 0;
  align-content: center;
  justify-content: center;
  color: ${colors.text};
`

const StyledPage = styled.div`
  margin-top: ${sizes.navBarHeight};
`

const Message = styled.div`
  p {
    margin: 0.25em 0;
  }
  a {
    color: ${colors.text};
    transition: 0.25s;

    &:hover {
      color: ${colors.accentColorful};
    }
  }
`

const rotate = keyframes`
  0% {
    transform: rotateY(0deg) rotateZ(0deg);
  }
  100% {
    transform: rotateY(360deg) rotateZ(360deg);
  }
`

const FourOhFour = styled.div`
  height: 100vh;
  width: 100vw;
  font-size: 50vmax;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: grid;
  align-content: center;
  justify-content: center;

  color: ${colors.accentColorful};
  opacity: 0.5;
  text-align: center;

  h2 {
    animation: ${rotate} 25s linear infinite;
    transform-origin: center center;
  }
`

const FourOhWut = styled(FourOhFour)`
  h2 {
    animation: ${rotate} 30s linear infinite reverse;
    transform-origin: center center;
  }
`

//   /* animation: ${spacing} 15s ease-in-out infinite alternate,
//  ${rotate} 25s linear infinite; */

const NotFoundPage = () => (
  <Wrapper>
    <Header />
    <SEO title="404: Not found" />
    <StyledPage>
      <FourOhFour>
        <h2>404</h2>
      </FourOhFour>
      <FourOhWut>
        <h2>404</h2>
      </FourOhWut>
      <Message>
        <h1>Page not found</h1>
        <p>Sorry, the URL you entered isn't available.</p>
        <p>
          Please <Link to="index.html">go home</Link> to find what you're
          looking for.
        </p>
      </Message>
    </StyledPage>
  </Wrapper>
)

export default NotFoundPage
