import React from 'react'

const IconMenu = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    stroke="#000"
    strokeLinecap="square"
    strokeWidth="2"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M 2 2 L 15 2" />
      <path d="M 2 8 L 15 8" />
      <path d="M 2 14 L 15 14" />
    </g>
  </svg>
)
export default IconMenu
